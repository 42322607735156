import { decreaseAccountBalance, increaseAccountBalance } from "../../income/helpers/incomeOptions";
// import { fetchPostMovementAccount } from "../helpers/movementAccountUtils"

export const AccountTransactionMixin = {
  methods: {
    // async createAccountTransaction(user, institution, amount, account, typeMovement) {
    //   try {
    //     const movementAccount = await fetchPostMovementAccount(user, institution, amount, account, typeMovement);
    //     const selectedMovement = typeMovement === 'increase'
    //       ? increaseAccountBalance
    //       : typeMovement === 'decrease'
    //         ? decreaseAccountBalance
    //         : null;
    //     if (selectedMovement) {
    //       await selectedMovement({
    //         id_autor: user,
    //         id_cuenta: account,
    //         monto: amount
    //       });
    //     } else {
    //       throw new Error('Tipo de movimiento no válido.');
    //     }

    //     return movementAccount;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    async  createAccountTransaction(user, account, amount, typeMovement) {
      try {
        const selectedMovement = typeMovement === 'increase'
          ? increaseAccountBalance
          : typeMovement === 'decrease'
            ? decreaseAccountBalance
            : null;
    
        if (selectedMovement) {
          await selectedMovement({
            id_autor: user,
            id_cuenta: account,
            monto: amount
          });
        } else {
          throw new Error('Tipo de movimiento no válido.');
        }
    
        return { message: 'Movimiento de cuenta realizado con éxito.' };
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    


  }
}